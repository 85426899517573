import React, { useContext, useState, useEffect } from 'react';
// reactstrap components
import { Row, Col, Button } from "reactstrap";
import * as request from 'request';


class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      planType : props.planType
    };
  }




  render(){
   
  
    return (
    
      <div id="sidebar-wrapper">
      <div className="sidebar-heading logo mt-3">
        <img src={require("assets/img/logo.svg")} alt="" />
        
      </div>
      <div className="list-group list-group-flush mt-2">
        <p className="clr-grey">HOME</p>
        <a href="/#pricing" className={"list-group-item list-group-item-action " + (this.state.planType == 'premium'? 'active':'')}>
          <img src={require("assets/img/home-icon.svg")} alt="" className="dash-mene-img"></img>
          <span>Pricing</span>
        </a>
      </div>
      <div className="list-group list-group-flush mt-2">
        <p className="clr-grey">RESIDENTIAL</p>
        <a href="/premium" className={"list-group-item list-group-item-action " + (this.state.planType == 'premium'? 'active':'')}>
          <img src={require("assets/img/home-icon.svg")} alt="" className="dash-mene-img"></img>
          <span>Hollow Premium</span>
        </a>
      <a href="/void" className={"list-group-item list-group-item-action " + (this.state.planType == 'void'? 'active':'')}>
            <img src={require("assets/img/home-icon.svg")} alt="" className="dash-mene-img"></img>
          <span>Hollow Void</span>
        </a>
        <a href="/prime" className={"list-group-item list-group-item-action " + (this.state.planType == 'prime'? 'active':'')}>
          <img src={require("assets/img/home-icon.svg")} alt="" className="dash-mene-img"></img>
          <span>Hollow Prime</span>
        </a>

        <p className="clr-grey mt-2">ISP</p>
        <a href="/datacenter" className={"list-group-item list-group-item-action " + (this.state.planType == 'isp'? 'active':'')}>
            <img src={require("assets/img/earth.svg")} alt="" className="dash-mene-img"></img>
          <span>Hollow ISP</span>
        </a>        
          
        </div>
      
      </div>
      
    );
  }
}

export default Sidebar;