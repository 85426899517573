import React from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter } from "react-router-dom";
// Core Components
import Header from 'components/Layout/Header';
import Footer from 'components/Layout/Footer';
// Page Components
import Home from 'views/Home';
import Dashboard from 'views/Dashboard';
import Datacenter from 'views/Datacenter';
import Premium from 'views/Premium';

import Void from 'views/Void';
import Prime from 'views/Prime';

import Login from 'views/Login';
import Register from 'views/Register';
import ResetPassword from 'views/ResetPassword';
import AboutUs from 'views/AboutUs';
import TOS from 'views/TOS';
import PrivacyPolicy from 'views/PrivacyPolicy';
import RefundPolicy from 'views/RefundPolicy';
import Success from "views/Success.js";
import Error from "views/Error.js";
import Error404 from "views/Error404.js";


const Main = withRouter(({ location }) => {
  return (
    <>
      {
        location.pathname !== '/login' &&
        location.pathname !== '/register' &&
        location.pathname !== '/reset-password' &&
        location.pathname !== '/datacenter' &&
        location.pathname !== '/premium' &&
        location.pathname !== '/void' &&
        location.pathname !== '/prime' &&


        <Header />
      }
      <main>
        <Switch>
          <Route
            exact
            path="/"
            render={props => <Home {...props} />}
          />
          <Route
            path="/login"
            render={props => <Login {...props} />}
          />
          <Route
            path="/register"
            render={props => <Register {...props} />}
          />
          <Route
            path="/reset-password"
            render={props => <ResetPassword {...props} />}
          />
          <Route
            path="/dashboard"
            render={props => <Dashboard {...props} />}
          />
          <Route
            path="/datacenter"
            render={props => <Datacenter {...props} />}
          />
          <Route
            path="/premium"
            render={props => <Premium {...props} />}
          />
           
           <Route
            path="/void"
            render={props => <Void {...props} />}
          />
          <Route
            path="/prime"
            render={props => <Prime {...props} />}
          />
          <Route
            path="/about-us"
            render={props => <AboutUs {...props} />}
          />
          <Route
            path="/terms-and-conditions"
            render={props => <TOS {...props} />}
          />
          <Route
            path="/privacy-policy"
            render={props => <PrivacyPolicy {...props} />}
          />
          <Route
            path="/refund-policy"
            render={props => <RefundPolicy {...props} />}
          />
          <Route
            path="/success"
            render={props => <Success {...props} />}
          />
          <Route
            path="/error"
            render={props => <Error {...props} />}
          />
          <Route
            path="/404"
            render={props => <Error404 {...props} />}
          />
          <Route
            from='*'
            to='/404'
          />
        </Switch>
      </main>
      {
        location.pathname !== '/dashboard' &&
        location.pathname !== '/datacenter' &&
        location.pathname !== '/premium' &&
        location.pathname !== '/void' &&
        location.pathname !== '/prime' &&

        location.pathname !== '/login' &&
        location.pathname !== '/register' &&
        location.pathname !== '/reset-password' &&
        location.pathname !== '/success' &&
        location.pathname !== '/error' &&
        location.pathname !== '/404' &&
        <Footer />
      }
    </>
  )
});

const App = () => {
  return (
    <Router>
      <Main />
    </Router>
  )
}

export default App;