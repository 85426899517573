import React from "react";
// reactstrap components
import { Row, Col, Button } from "reactstrap";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name : props.name,
      avatar : props.avatar?props.avatar : null
    };
    
  }

  componentDidUpdate(prevProps) {
    if(prevProps.name !== this.props.name) {
      this.setState({name: this.props.name});
    }
  }
  

  render(){
  return (
    <div className="col-sm-12">
          <div className="second-top">
            <div className="s-left">
              <img src={require("assets/img/profile-b.png")} alt=""/>
              <div className="profile-details pl-3">
                <div className="welcome">Welcome back,</div>
                <div className="p-name">{this.state.name}</div>
              </div>
            </div>
            <div className="s-right">
              <div className="box-grey mr-2">
                <img src={require("assets/img/support.svg")} alt=""/>
                <div className="profile-details pl-3">
                  <div className="welcome">Need Help?</div>
                  <div className="p-name">Support</div>
                </div>
              </div>
              <div className="box-grey">
                <img src={require("assets/img/discord.png")} alt=""/>
                <div className="profile-details pl-3">
                  <div className="welcome">Discord</div>
                  <div className="p-name">Join Server</div>
                </div>
              </div>
            </div>
          </div>
      </div>  );
  }
}

export default Header;