import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Input,
  Table,
  Tooltip
} from 'reactstrap';
import * as request from 'request';
import Select from 'react-select';
import { FirebaseContext } from 'contexts/Firebase';
import {
  resiPremiumASN,
  resiPremiumCities,
  resiPremiumCountries,
  resiPremiumSites
} from 'Services/ResidentialPremium';
import {
  PageLoader,
  CheckoutModal,
  showToastAlert,
  DoughnutChart,
  getDate,
  validateIPv4,
  generateRandomString,
  copyData,
  downloadFile
} from 'Services/Utility';


const Dashboard = () => {
  const { user, firebase } = useContext(FirebaseContext);
  const history = useHistory();
  const [dcPlans, setDCPlans] = useState([]);
  const [userDCPlans, setUserDCPlans] = useState([]);
  const [dcAuthTokenList, setDCAuthTokenList] = useState([]);
  const [dcAuthToken, setDCAuthToken] = useState({ value: "", label: "AuthToken" });
  const [dcProxies, setDCProxies] = useState([]);
  const [dcSrcIPList, setDCSrcIPList] = useState([]);
  const [dcSrcIP, setDCSrcIP] = useState({ value: "", label: "Auth IPs" });
  const [dcIP, setDCIP] = useState('');
  const [authType, setAuthType] = useState('user');

  const [dcPlanExpiry, setDCExpiry] = useState('N/A');
  const [isDCPlansActive, setDCPlansActive] = useState(null);
  const [isDCRenewActive, setDCRenewActive] = useState(null);

  // const [resiPlans, setResiPlans] = useState([]);
  // const [isResiPlansActive, setResiPlansActive] = useState(null);
  const [userResiPlans, setUserResiPlans] = useState([]);
  const [resiLocations, setResiLocations] = useState([]);
  const [resiContinentList, setResiContinentList] = useState([]);
  const [resiCountryList, setResiCountryList] = useState([]);
  const [resiContinent, setResiContinent] = useState({ value: "", label: "Continent" });
  const [resiCountry, setResiCountry] = useState({ value: "", label: "Country" });

  // const [resiPremPlans, setResiPremPlans] = useState([]);
  // const [isResiPremPlansActive, setResiPremPlansActive] = useState(null);
  const [userResiPremPlans, setUserResiPremPlans] = useState([]);
  const [resiPremCountryList, setResiPremCountryList] = useState([]);
  const [resiPremCityList, setResiPremCityList] = useState([]);
  const [resiPremASNList, setResiPremASNList] = useState([]);
  const [resiPremCountry, setResiPremCountry] = useState({ value: "", label: "Country" });
  const [resiPremCity, setResiPremCity] = useState({ value: "", label: "City" });
  const [resiPremASN, setResiPremASN] = useState({ value: "", label: "ASN" });
  const [resiPremSite, setResiPremSite] = useState({ value: "", label: "Site" });
  const [resiPremSiteList] = useState(resiPremiumSites.map(site => {
    return { label: site.name, value: site.name }
  }));
  const [isResiPremSiteSelected, setResiPremSiteSelected] = useState(false);

  const [resiProxyType, setResiProxyType] = useState({ value: "sticky", label: "Sticky" });
  const [nbrOfProxies, setNbrOfProxies] = useState(255);
  const [resiPlanTypeList] = useState([
    { value: "HollowVoid", label: "HollowVoid" },
    { value: "HollowPremium", label: "HollowPremium" }
  ]);
  const [resiProxyTypeList] = useState([
    { value: "sticky", label: "Sticky" },
    { value: "rotating", label: "Rotating" }
  ]);
  const [resiPlanType, setResiPlanType] = useState({ value: "HollowVoid", label: "HollowVoid" });
  const [resiExpiry, setResiExpiry] = useState('N/A');
  const [resiDataUsage, setResiDataUsage] = useState('0.00 / 0.00 GB');
  const [resiProxyAuth, setResiProxyAuth] = useState('username:password');
  const [resiProxies, setResiProxies] = useState([]);
  const [tooltips, setTooltips] = useState({
    'dropGuide': false, 'support': false, 'resiFAQ': false, 'dcFAQ': false
  });
  const [dataUsageCharts, setDataUsageCharts] = useState([<DoughnutChart key={Math.random()} />]);
  const [orderHistory, setOrderHistory] = useState([]);
  const [checkoutModals, setCheckoutModals] = useState([]);
  const [loading, setLoading] = useState(true);

  const getDCPlans = (idToken) => {
    return new Promise((resolve, reject) => {
      let options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/datacenter/plans`,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          idToken
        })
      };

      return request(options, (error, response, body) => {
        let resData = body && JSON.parse(body);
        if (error || response.statusCode !== 200) {
          let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while fetching Datacenter proxies!';
          showToastAlert('Error', errorMessage);
          resolve(null);
        } else {
          return resolve(resData);
        }
      });
    });
  }

  const getResiPlans = (idToken) => {
    return new Promise((resolve, reject) => {
      let options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/residential/plans`,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          idToken
        })
      };

      return request(options, (error, response, body) => {
        let resData = body && JSON.parse(body);
        if (error || response.statusCode !== 200) {
          let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while fetching HollowVoid plan!';
          showToastAlert('Error', errorMessage);
          resolve(null);
        } else {
          return resolve(resData);
        }
      });
    });
  }

  const requestAuthChange = async (authToken, newType) =>{
    let idToken = await firebase.auth.currentUser.getIdToken(true);

    return new Promise((resolve, reject) => {
      let options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/datacenter/changeAuth`,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          idToken: idToken,
          authtoken: authToken,
          authtype: newType
        })
      };

      return request(options, (error, response, body) => {
        let resData = body && JSON.parse(body);
        if (error || response.statusCode !== 200) {
          let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while fetching Datacenter proxies!';
          showToastAlert('Error', errorMessage);
          resolve(null);
        } else {
          setAuthType(newType);
          setLoading(false);

          showToastAlert('Success', 'Authtype Changed successfully');
          window.location.reload();

          return resolve(resData);
        }
      });
    });
  }

  const changeAuthType = async () => {
    setLoading(true);

    let newType = '';
    if(authType === 'ip'){
      newType = 'user';
    }else{
      newType = 'ip';
    }
    await requestAuthChange(dcAuthToken.value, newType);
    
  }

  const getResiPremPlans = (idToken) => {
    return new Promise((resolve, reject) => {
      let options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/residential_premium/plans`,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          idToken
        })
      };

      return request(options, (error, response, body) => {
        let resData = body && JSON.parse(body);
        if (error || response.statusCode !== 200) {
          let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while fetching HollowPremium plan!';
          showToastAlert('Error', errorMessage);
          resolve(null);
        } else {
          return resolve(resData);
        }
      });
    });
  }

  const refreshResiPremProxyAuth = async () => {
    let idToken = await firebase.auth.currentUser.getIdToken(true);
    return new Promise((resolve, reject) => {
      let options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/residential_premium/refreshProxyAuth`,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          idToken
        })
      };

      return request(options, (error, response, body) => {
        let resData = body && JSON.parse(body);
        if (error || response.statusCode !== 200) {
          let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while refreshing proxy auth!';
          showToastAlert('Error', errorMessage);
          resolve(null);
        } else {
          showToastAlert('Success', 'HollowPremium Proxy Auth is successfully refreshed');
          return resolve(resData);
        }
      });
    });
  }

  const onChangeResiPlanType = (event) => {
    setResiPlanType({ value: event.value, label: event.label });
    setResiProxies([]);
    if (event.value === 'HollowVoid') {
      updateResiMetaData(userResiPlans);
    }
    if (event.value === 'HollowPremium') {
      updateResiPremMetaData(userResiPremPlans);
    }
  }

  const updateDCPlansMetaData = (plans, isPlansActive, userPlans, planOptions, authToken) => {
    const value = planOptions.filter(option => option.value === authToken)[0];
    if (!value) {
      return;
    }
    setDCAuthToken(value);
    const dcPlan = userPlans.filter(plan => plan.authToken === authToken)[0];
    const planData = dcPlan.planData;
    console.log(planData);
    setAuthType(planData.authtype);
    var dcProxies;
    if(planData.authtype === 'ip'){

      dcProxies = planData.proxies.join('\r\n');
    }else{
      dcProxies = planData.proxies.join(`:${planData.username}:${planData.password}\r\n`);
      dcProxies = dcProxies + `:${planData.username}:${planData.password}`;
    }
    const dcSrcIPList = planData.srcips.map(ip => {
      return { value: ip, label: ip }
    });
    const dcPlanExpiry = getDate(new Date(parseInt(planData.starttime) * 1000 + parseInt(planData.expiryhours) * 3600000));

    let isDCRenewActive = false;
    let plan = plans.filter(p => p.planID === dcPlan.planID)[0];

    if (dcPlan.subPlanID && plan['subPlans'][dcPlan.subPlanID]) {
      isDCRenewActive = plan['subPlans'][dcPlan.subPlanID].isRenewable;
    }
    setDCProxies(dcProxies);
    setDCSrcIPList(dcSrcIPList);
    setDCSrcIP(dcSrcIPList[0]);
    setDCExpiry(dcPlanExpiry);
    setDCRenewActive(isDCRenewActive);
  }

  const onChangeDCAuthToken = (authToken) => {
    updateDCPlansMetaData(dcPlans, isDCPlansActive, userDCPlans, dcAuthTokenList, authToken);
  }

  const renewDCPlan = async () => {
    setCheckoutModals([]);
    let discordId = await firebase.db.ref(`users/${firebase.auth.currentUser.uid}/discordId`).once('value');
    discordId = discordId.val();
    if (!discordId) {
      showToastAlert('Error', 'Please join discord server to purchase a plan');
      return;
    }

    let authToken = dcAuthToken.value;
    if (!authToken) {
      return;
    }
    let proxyData = userDCPlans.filter(plan => plan.authToken === authToken)[0];
    if (!proxyData || !proxyData.subPlanID) {
      return;
    }

    let showModal = true;
    let checkoutPlanID = proxyData.planID;
    let checkoutsubPlanID = proxyData.subPlanID;
    let checkoutPlanType = 'datacenter';
    let updatePlanRefID = proxyData.updatePlanRefID;

    let planData = dcPlans.filter(plan => plan.planID === checkoutPlanID)[0];
    let checkoutPlanPrice = planData['subPlans'][checkoutsubPlanID]['price'];
    let planPrice = `Plan Price $${checkoutPlanPrice}`;
    setCheckoutModals([
      <CheckoutModal
        showModal={showModal}
        checkoutPlanID={checkoutPlanID}
        checkoutsubPlanID={checkoutsubPlanID}
        updatePlanRefID={updatePlanRefID}
        checkoutPlanType={checkoutPlanType}
        checkoutPlanPrice={checkoutPlanPrice}
        planPrice={planPrice}
        key={Math.random()}
        firebase={firebase}
      />
    ]);
  }

  const addSrcIPs = () => {
    if (dcIP && validateIPv4(dcIP)) {
      let srcips = dcSrcIPList.map(ip => ip.value);
      srcips.push(dcIP);
      handleSrcIPs(srcips);
    }
  }

  const removeSrcIP = () => {
    let srcips = dcSrcIPList.map(ip => ip.value).filter(ip => ip !== dcSrcIP.value);
    handleSrcIPs(srcips);
  }

  const handleSrcIPs = async (srcips) => {
    srcips = Array.from(new Set(srcips));
    if (srcips.length && srcips.every(validateIPv4)) {
      setLoading(true);
      let idToken = await firebase.auth.currentUser.getIdToken(true);
      let options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/datacenter/changeIP`,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          idToken,
          srcips,
          authtoken: dcAuthToken.value,
        })
      };

      request(options, (error, response, body) => {
        let resData = body && JSON.parse(body);
        if (error || response.statusCode !== 200) {
          let errorMessage = error ? error : resData ? resData.message : 'Error occurred while updating Authenticated IPs';
          setLoading(false);
          showToastAlert('Error', errorMessage);
        } else {
          if (resData.status === 'success' && Object.keys(resData.data).length) {
            const dcPlans = userDCPlans.map(plan => {
              if (plan.authToken === dcAuthToken.value) {
                plan.planData = resData.data;
              }
              return plan;
            });
            setUserDCPlans(dcPlans);

            const dcSrcIPList = resData.data.srcips.map(ip => {
              return { value: ip, label: ip }
            });
            setDCSrcIPList(dcSrcIPList);
            setDCSrcIP(dcSrcIPList[0]);
            setDCIP('');
            setLoading(false);
            showToastAlert('Success', 'Authenticated IPs updated successfully');
          } else {
            setLoading(false);
          }
        }
      });
    }
  };

  const updateResiMetaData = (userPlans) => {
    let resiDataUsage = '0.00/0.00 GB', resiExpiry = 'N/A', resiProxyAuth = 'username:password', chartText = '100%',
      key = Math.random(), chartDataSet = [100, 0];

    if (userPlans.length) {
      let plan = userPlans[0];
      let planData = plan.planData;
      let dataUsedGB = parseFloat((parseFloat(planData.dataUsage) / 1000000000).toFixed(2));
      let dataLimitGB = parseFloat((parseInt(planData.dataLimitMegaBytes) / 1000).toFixed(2));
      let dataRemainGB = parseFloat((dataLimitGB - dataUsedGB).toFixed(2));
      resiDataUsage = `${dataUsedGB.toFixed(2)} / ${dataLimitGB.toFixed(2)} GB`;
      resiExpiry = getDate(new Date(new Date(planData.createDate).getTime() + parseInt(planData.validityHours) * 3600000));
      resiProxyAuth = `${planData.name}:${planData.pass}`;
      chartText = `${((100 * dataUsedGB) / dataLimitGB).toFixed(2)}%`;
      chartDataSet = [dataUsedGB, dataRemainGB];
      key = planData.name;
    }
    setDataUsageCharts([
      <DoughnutChart key={key} text={chartText} dataset={chartDataSet} />
    ]);
    setResiDataUsage(resiDataUsage);
    setResiExpiry(resiExpiry);
    setResiProxyAuth(resiProxyAuth);
    setLoading(false);
  }

  const updateResiPremMetaData = (userPlans) => {
    let resiDataUsage = '0.00/0.00 GB', resiExpiry = 'N/A', resiProxyAuth = 'username:password', chartText = '100%',
      key = Math.random(), chartDataSet = [100, 0];

    if (userPlans.length) {
      let plan = userPlans[0];
      let planData = plan.planData;
      let dataUsedGB = parseFloat(planData.traffic);
      let dataLimitGB = parseFloat(planData.traffic_limit);
      let dataRemainGB = parseFloat((dataLimitGB - dataUsedGB).toFixed(2));
      resiDataUsage = `${dataUsedGB.toFixed(2)} / ${dataLimitGB.toFixed(2)} GB`;
      resiExpiry = 'N/A';
      resiProxyAuth = `${planData.username}:${plan.password}`;
      chartText = `${((100 * dataUsedGB) / dataLimitGB).toFixed(2)}%`;
      chartDataSet = [dataUsedGB, dataRemainGB];
      key = planData.username;
    }
    setDataUsageCharts([
      <DoughnutChart key={key} text={chartText} dataset={chartDataSet} />
    ]);
    setResiDataUsage(resiDataUsage);
    setResiExpiry(resiExpiry);
    setResiProxyAuth(resiProxyAuth);
  }

  const onChangeResiContinent = (event) => {
    let countryList = [];
    let countries = resiLocations.filter(location => location['continent'] === event.value);
    countries.forEach((country) => {
      countryList.push({ value: country['name'], label: country['name'] });
    });
    setResiCountryList(countryList);
    setResiCountry(countryList[0]);
    setResiContinent({ value: event.value, label: event.label });
  }

  const onChangeResiCountry = (event) => {
    setResiCountry({ value: event.value, label: event.label });
  }

  const onChangeResiProxyType = (event) => {
    setResiProxyType({ value: event.value, label: event.label });
  }

  const onChangeResiPremCountry = (event) => {
    setResiPremSiteSelected(false);
    let cityList = [];
    resiPremiumCities.filter(city => city.Country.toLowerCase() === event.value).forEach((city) => {
      cityList.push({ value: city.City, label: city.City });
    });
    cityList.unshift({ value: "", label: "City" });
    setResiPremCityList(cityList);
    setResiPremCity(cityList[0]);

    let asnList = [];
    resiPremiumASN.filter(asn => asn.Country.toLowerCase() === event.value).forEach((asn) => {
      asnList.push({ value: asn.ASN, label: `${asn.Carrier} - ${asn.ASN}` });
    });
    asnList.unshift({ value: "", label: "ASN" });
    setResiPremASNList(asnList);
    setResiPremASN(asnList[0]);

    setResiPremCountry({ value: event.value, label: event.label });
  }

  const onChangeResiPremCity = (event) => {
    setResiPremCity({ value: event.value, label: event.label });
  }

  const onChangeResiPremASN = (event) => {
    setResiPremASN({ value: event.value, label: event.label });
  }

  const onChangeResiPremSite = (event) => {
    setResiPremSiteSelected(true);
    setResiPremSite({ value: event.value, label: event.label });
    let site = resiPremiumSites.filter(site => site.name === event.value)[0];
    let country = site.country;
    let domains = site.domains;
    let asn = site.ASN;
    let proxies = [];
    let generatedProxies = 0;
    let isBackConnect = site.isBackConnect?true: false;
    for (let i = 0; i < country.length; i++) {
      let noOfPrxoies = i === country.length - 1 ? nbrOfProxies - generatedProxies : Math.ceil(nbrOfProxies / country.length);
      let domainProxies = 0;
      for (let j = 0; j < domains.length; j++) {
        let selectedASN, isASN = false;
        if(asn){
          isASN = true;
          selectedASN = asn[Math.floor(Math.random() * asn.length)];   
        }
        let nProxies = j === domains.length - 1 ? noOfPrxoies - domainProxies : Math.ceil(noOfPrxoies / domains.length);
        let tempProxies = generateResiPremProxies(userResiPremPlans, resiPremCountry, resiPremCity, resiPremASN, resiProxyType, nProxies, country[i], 'site', domains[j], isASN, selectedASN, isBackConnect);
        proxies.push(...tempProxies);
        generatedProxies += nProxies;
        domainProxies += nProxies;
      }
    }
    // shuffle generated proxies
    for (let i = proxies.length - 1; i > 0; i--) {
      const rand = Math.floor(Math.random() * (i + 1));
      [proxies[i], proxies[rand]] = [proxies[rand], proxies[i]];
    }
    proxies = proxies.slice(0,nbrOfProxies);
    setResiProxies(proxies.join('\r\n'));
  }

  const onChangenbrOfProxy = (event) => {
    let nbrOfProxies = parseInt(event.target.value);
    if (nbrOfProxies) {
      nbrOfProxies = Math.min(nbrOfProxies, 1000);
      setNbrOfProxies(nbrOfProxies);
    }
  }

  const generateQuickProxy = (country) => {
    if (resiPlanType.value === 'HollowVoid') {
      if (country === 'US') {
        country = 'United States';
      } else if (country === 'GB') {
        country = 'United Kingdom';
      } else if (country === 'CA') {
        country = 'Canada';
      } else if (country === 'DE') {
        country = 'Germany';
      }
      let proxies = generateResiProxies(userResiPlans, resiLocations, resiCountry, resiProxyType, nbrOfProxies, country, 'sticky');
      setResiProxies(proxies.join('\r\n'));
    }
    if (resiPlanType.value === 'HollowPremium') {
      if (country === 'US') {
        country = 'us';
      } else if (country === 'GB') {
        country = 'gb';
      } else if (country === 'CA') {
        country = 'ca';
      } else if (country === 'DE') {
        country = 'de';
      }
      let proxies = generateResiPremProxies(userResiPremPlans, resiPremCountry, resiPremCity, resiPremASN, resiProxyType, nbrOfProxies, country, 'sticky');
      setResiProxies(proxies.join('\r\n'));
    }
  }

  const generateResiProxies = (userResiPlans, resiLocations, resiCountry, resiProxyType, nbrOfProxies, selectedCountry = '', selectedProxyType = '') => {
    try {
      setLoading(true);
      let country = selectedCountry || resiCountry.value;
      let proxyType = selectedProxyType || resiProxyType.value;
      let nbrOfProxy = nbrOfProxies || 255;
      let location = resiLocations.filter(loc => loc.name === country)[0];
      if (!proxyType || !nbrOfProxy || !location) {
        throw new Error('Invalid proxy configurations !');
      }

      let domain = location.domain;
      let portStart = location.portStart;
      let portEnd = location.portEnd;

      let proxyData = userResiPlans[0];
      if (!proxyData || !proxyData.planData) {
        throw new Error('Invalid proxy authToken !');
      }
      let name = proxyData.planData.name;
      let pass = proxyData.planData.pass;
      let proxies = [];

      if (proxyType === 'sticky') {
        for (let i = 1; i <= parseInt(nbrOfProxy); i++) {
          let randomStr = generateRandomString(10);
          let port = Math.floor(Math.random() * (portEnd - portStart + 1) + portStart);
          let proxy = `${domain}:${port}:${name}:${pass}-${randomStr}`;
          proxies.push(proxy);
        }
      } else if (proxyType === 'rotating') {
        let port = Math.floor(Math.random() * (portEnd - portStart + 1) + portStart);
        let proxy = `${domain}:${port}:${name}:${pass}`;
        proxies.push(proxy);
      }
      setLoading(false);
      return proxies;
    } catch (error) {
      showToastAlert('Error', error.message);
      setLoading(false);
      return [];
    }
  };

  const generateResiPremProxies = (userResiPremPlans, resiPremCountry, resiPremCity, resiPremASN, resiProxyType, nbrOfProxies, selectedCountry = '', selectedProxyType = '', domain = '', isASN = false, selectedASN = '', isBackConnect = false) => {
    try {
      setLoading(true);
      let country_code = selectedCountry || resiPremCountry.value;
      let proxyType = selectedProxyType || resiProxyType.value;
      let nbrOfProxy = nbrOfProxies || 255;
      let location = resiPremiumCountries.filter(country => country.country_code === country_code)[0];
      if (!proxyType || !nbrOfProxy || !location) {
        throw new Error('Invalid proxy configurations !');
      }

      let isp, city;
      if (!selectedCountry) {
        isp = resiPremASN.value;
        city = resiPremCity.value;
      }
      country_code = country_code.toUpperCase();

      let proxyData = userResiPremPlans[0];
      if (!proxyData || !proxyData.planData) {
        throw new Error('Invalid proxy username !');
      }
      let name = proxyData.planData.username;
      let pass = proxyData.password;
      let ispStr = isp ? `-asn-${isp}` : '';
      let cityStr = city ? `-city-${city}` : '';
      let proxies = [];

      if (proxyType === 'sticky') {
        for (let i = 1; i <= parseInt(nbrOfProxy); i++) {
          let random = generateRandomString(8);
          let proxy = `hl.hollowpremium.com:7777:customer-${name}-cc-${country_code}${cityStr}${ispStr}-sessid-${random}-sesstime-13:${pass}`;
          proxies.push(proxy);
        }
      } else if (proxyType === 'rotating') {
        for (let i = 1; i <= parseInt(nbrOfProxy); i++) {
          let proxy = `hl.hollowpremium.com:7777:customer-${name}-cc-${country_code}${cityStr}${ispStr}:${pass}`;
          proxies.push(proxy);
        }
      } else if (proxyType === 'site') {
        let portStart = location.start_port;
        let portEnd = location.end_port;
        let ispStr = isASN ? `-asn-${selectedASN}` : '';
        
        for (let i = 1; i <= parseInt(nbrOfProxy); i++) {
          let port = Math.floor(Math.random() * (portEnd - portStart + 1) + portStart);
          let proxy = '';
          if(isBackConnect){
            domain = 'hl.hollowpremium.com';
            let random = generateRandomString(8);
            port = 7777;
            proxy = `${domain}:${port}:customer-${name}-cc-${country_code}-sessid-${random}-sesstime-13${ispStr}:${pass}`;
          }else{
            proxy = `${domain}:${port}:customer-${name}-cc-${country_code}${ispStr}:${pass}`;
          }
          proxies.push(proxy);
        }
      }
      setLoading(false);
      return proxies;
    } catch (error) {
      showToastAlert('Error', error.message);
      setLoading(false);
      return [];
    }
  }

  const copyProxies = (type) => {
    let proxies = '';
    if (type === 'DC') {
      proxies = document.getElementById('dcProxyList').value;
    }
    if (type === 'RESI') {
      proxies = document.getElementById('resiProxyList').value;
    }
    copyData(proxies);
  }

  const downloadProxies = (type) => {
    let proxies = '', fileName = '';
    if (type === 'DC') {
      fileName = `HollowISP_${dcAuthToken.value}.txt`;
      proxies = document.getElementById('dcProxyList').value;
    }
    if (type === 'RESI') {
      fileName = resiPlanType.value === 'HollowVoid' ? `HollowVoid.txt` : `HollowPremium.txt`;
      proxies = document.getElementById('resiProxyList').value;
    }
    downloadFile(fileName, proxies);
  }

  const toggleTooltip = (type) => {
    let toolstipsCopy = { ...tooltips };
    toolstipsCopy[type] = !(toolstipsCopy[type]);
    setTooltips(toolstipsCopy);
  }

  useEffect(() => {
    if (userResiPlans.length && resiLocations.length && resiContinentList.length && resiCountryList.length && resiContinent.value && resiCountry.value && resiProxyType.value && nbrOfProxies) {
      if (resiPlanType.value === 'HollowVoid') {
        let proxies = generateResiProxies(userResiPlans, resiLocations, resiCountry, resiProxyType, nbrOfProxies);
        setResiProxies(proxies.join('\r\n'));
      }
    }
  }, [resiPlanType, userResiPlans, resiLocations, resiContinentList, resiCountryList, resiContinent, resiCountry, resiProxyType, nbrOfProxies]);

  useEffect(() => {
    if (userResiPremPlans.length && resiPremCountryList.length && resiPremCityList.length && resiPremASNList.length && resiPremCountry.value && resiProxyType.value && nbrOfProxies) {
      if (resiPlanType.value === 'HollowPremium' && !isResiPremSiteSelected) {
        let proxies = generateResiPremProxies(userResiPremPlans, resiPremCountry, resiPremCity, resiPremASN, resiProxyType, nbrOfProxies);
        setResiProxies(proxies.join('\r\n'));
      }
    }
  }, [resiPlanType, userResiPremPlans, resiPremCountryList, resiPremCityList, resiPremASNList, resiPremCountry, resiPremCity, resiPremASN, resiProxyType, nbrOfProxies, isResiPremSiteSelected]);

  useEffect(() => {
    (async function () {
      firebase.auth.onAuthStateChanged(async (user) => {
        try {
          if (!user) {
            history.push('/login');
            return;
          }
          let idToken = await firebase.auth.currentUser.getIdToken(true);
          let plans = await Promise.all([getDCPlans(idToken), getResiPlans(idToken), getResiPremPlans(idToken)]);
          if (plans.every(i => i === null)) {
            setLoading(false);
          }
          let orderHistoryList = [];

          if (plans[0] && plans[0].status === 'success') {
            let dcData = plans[0];
            if (Object.keys(dcData.data).length) {
              setDCPlansActive(dcData.isActivePlan);
              const dcPlanList = Object.keys(dcData.plans).map(key => ({
                ...dcData.plans[key],
                planID: key,
              }));
              setDCPlans(dcPlanList);

              const userDcPlans = Object.keys(dcData.data).map(key => ({
                ...dcData.data[key],
                authToken: key,
              }));
              setUserDCPlans(userDcPlans);

              const dcAuthTokenList = userDcPlans.map(plan => {
                return { value: plan.authToken, label: plan.authToken }
              });
              setDCAuthTokenList(dcAuthTokenList);

              updateDCPlansMetaData(dcPlanList, dcData.isActivePlan, userDcPlans, dcAuthTokenList, dcAuthTokenList[0]['value']);
            }
            orderHistoryList = [...orderHistoryList, ...dcData.orderHistory];
          }

          if (plans[1] && plans[1].status === 'success') {
            let resiData = plans[1];
            if (Object.keys(resiData.data).length) {
              // setResiPlansActive(resiData.isActivePlan);
              // const resiPlanList = Object.keys(resiData.plans).map(key => ({
              //   ...resiData.plans[key],
              //   planID: key,
              // }));
              // setResiPlans(resiPlanList);

              const userResiPlans = Object.keys(resiData.data).map(key => ({
                ...resiData.data[key],
                authToken: key,
              }));
              setUserResiPlans(userResiPlans);

              setResiLocations(resiData.countries);
              let continentList = [];
              resiData.countries.forEach((location) => {
                continentList.push({ value: location['continent'], label: location['continent'] });
              });
              continentList = continentList.filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i);
              let continent = continentList[0] || { value: "", label: "Continent" };
              setResiContinentList(continentList);
              setResiContinent(continent);

              let countryList = [];
              let countries = resiData.countries.filter(country => country['continent'] === continent['value']);
              countries.forEach((country) => {
                countryList.push({ value: country['name'], label: country['name'] });
              });
              let country = countryList[0] || { value: "", label: "Country" };
              setResiCountryList(countryList);
              setResiCountry(country);

              updateResiMetaData(userResiPlans);
            } else {
              updateResiMetaData([]);
            }
            orderHistoryList = [...orderHistoryList, ...resiData.orderHistory];
          }

          if (plans[2] && plans[2].status === 'success') {
            let resiPremData = plans[2];
            if (Object.keys(resiPremData.data).length) {
              // setResiPremPlansActive(resiPremData.isActivePlan);
              // const resiPlanList = Object.keys(resiPremData.plans).map(key => ({
              //   ...resiPremData.plans[key],
              //   planID: key,
              // }));
              // setResiPremPlans(resiPlanList);

              const userResiPremPlans = Object.keys(resiPremData.data).map(key => ({
                ...resiPremData.data[key],
                authToken: key,
              }));
              setUserResiPremPlans(userResiPremPlans);

              let countryList = [];
              resiPremiumCountries.filter(c => c.start_port || c.end_port).forEach((country) => {
                countryList.push({ value: country['country_code'], label: country['country_name'] });
              });
              let country = countryList[0] || { value: "", label: "Country" };
              setResiPremCountryList(countryList);
              setResiPremCountry(country);

              let cityList = [];
              resiPremiumCities.filter(city => city.Country.toLowerCase() === country.value).forEach((city) => {
                cityList.push({ value: city.City, label: city.City });
              });
              cityList.unshift({ value: "", label: "City" });
              setResiPremCityList(cityList);
              setResiPremCity(cityList[0]);

              let asnList = [];
              resiPremiumASN.filter(asn => asn.Country.toLowerCase() === country.value).forEach((asn) => {
                asnList.push({ value: asn.ASN, label: `${asn.Carrier}` });
              });
              asnList.unshift({ value: "", label: "ASN" });
              setResiPremASNList(asnList);
              setResiPremASN(asnList[0]);
            }
            orderHistoryList = [...orderHistoryList, ...resiPremData.orderHistory];
          }
          orderHistoryList.sort((obj1, obj2) => (obj1.createdAt - obj2.createdAt) * -1);
          setOrderHistory(orderHistoryList);
        } catch (error) {
          showToastAlert('Error', error.message);
          setLoading(false);
        }
      });
    })();
  }, [history, firebase.auth]);

  return (
    <>
      {loading && <PageLoader />}
      <Container>
        <Row>
          <Col lg="5">
            <Row>
              <Col lg="6" className="mt-3">
                <div className="dashboard-card generate-card">
                  <div className="card-header">
                    <img
                      alt="..."
                      className="card-header-img"
                      src={require(`assets/img/dashboard_generate.svg`)}
                    />
                    Generate
                  </div>

                  <div className="card-body">
                    <Row>
                      <Col xs="12">
                        <div className="label">Plan</div>
                        <Select
                          className="react-select react-select-secondary select-dashboard w-100"
                          classNamePrefix="react-select"
                          name="resiPlanType"
                          id="resiPlanType"
                          value={resiPlanType}
                          options={resiPlanTypeList}
                          onChange={onChangeResiPlanType}
                        />
                      </Col>

                      <Col xs="12" className="quick-create justify-content-center">
                        <div className="label text-center">Quick Create</div>
                        <Button
                          disabled={resiPlanType.value === 'HollowVoid' ?
                            userResiPlans.length === 0 : userResiPremPlans.length === 0}
                          className="btn-dashboard"
                          onClick={() => generateQuickProxy('US')}
                        >
                          US Static
                        </Button>
                        {/* <Button
                          disabled={resiPlanType.value === 'HollowVoid' ?
                            userResiPlans.length === 0 : userResiPremPlans.length === 0}
                          className="btn-dashboard"
                          onClick={() => generateQuickProxy('GB')}
                        >
                          GB Static
                        </Button> */}
                        <Button
                          disabled={resiPlanType.value === 'HollowVoid' ?
                            userResiPlans.length === 0 : userResiPremPlans.length === 0}
                          className="btn-dashboard"
                          onClick={() => generateQuickProxy('CA')}
                        >
                          CA Static
                        </Button>
                        {/* <Button
                          disabled={resiPlanType.value === 'HollowVoid' ?
                            userResiPlans.length === 0 : userResiPremPlans.length === 0}
                          className="btn-dashboard"
                          onClick={() => generateQuickProxy('DE')}
                        >
                          DE Static
                        </Button> */}
                        {resiPlanType && resiPlanType.value === 'HollowPremium' ?
                          <Select
                            disabled={userResiPremPlans.length === 0}
                            className="react-select react-select-secondary select-dashboard"
                            classNamePrefix="react-select"
                            name="resiPremSites"
                            id="resiPremSites"
                            value={resiPremSite}
                            options={resiPremSiteList}
                            onChange={onChangeResiPremSite}
                          />
                          : null}

                        {resiPlanType && resiPlanType.value === 'HollowVoid' ? <div className="mt-5"></div> : null}
                      </Col>

                      <Col xs="12" className="proxy-generator justify-content-center">
                        <div className="label text-center">Custom Create</div>
                        {resiPlanType && resiPlanType.value === 'HollowVoid' ?
                          <>
                            <Select
                              className="react-select react-select-secondary select-dashboard"
                              classNamePrefix="react-select"
                              name="resiContinent"
                              id="resiContinent"
                              value={resiContinent}
                              options={resiContinentList}
                              onChange={onChangeResiContinent}
                            />
                            <Select
                              className="react-select react-select-secondary select-dashboard"
                              classNamePrefix="react-select"
                              name="resiCountry"
                              id="resiCountry"
                              value={resiCountry}
                              options={resiCountryList}
                              onChange={onChangeResiCountry}
                            />
                          </>
                          : null}

                        {resiPlanType && resiPlanType.value === 'HollowPremium' ?
                          <>
                            <Select
                              className="react-select react-select-secondary select-dashboard"
                              classNamePrefix="react-select"
                              name="resiPremCountry"
                              id="resiPremCountry"
                              value={resiPremCountry}
                              options={resiPremCountryList}
                              onChange={onChangeResiPremCountry}
                            />
                            <Select
                              className="react-select react-select-secondary select-dashboard"
                              classNamePrefix="react-select"
                              name="resiPremCity"
                              id="resiPremCity"
                              value={resiPremCity}
                              options={resiPremCityList}
                              onChange={onChangeResiPremCity}
                            />
                            <Select
                              className="react-select react-select-secondary select-dashboard"
                              classNamePrefix="react-select"
                              name="resiPremASN"
                              id="resiPremASN"
                              value={resiPremASN}
                              options={resiPremASNList}
                              onChange={onChangeResiPremASN}
                            />
                          </>
                          : null}

                        <Select
                          className="react-select react-select-secondary select-dashboard"
                          classNamePrefix="react-select"
                          name="resiProxyType"
                          id="resiProxyType"
                          value={resiProxyType}
                          options={resiProxyTypeList}
                          onChange={onChangeResiProxyType}
                        />
                      </Col>

                      <Col xs="12">
                        <div className="label">Number of Proxies</div>
                        <Input
                          min="1"
                          max="1000"
                          type="number"
                          placeholder="Quantity..."
                          name="nbrOfProxies"
                          className="input-dashboard w-100"
                          value={nbrOfProxies}
                          onChange={onChangenbrOfProxy}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>

              <Col lg="6" className="mt-3">
                <div className="dashboard-card usage-card">
                  <div className="card-header">
                    <img
                      alt="..."
                      className="card-header-img"
                      src={require(`assets/img/dashboard_usage.svg`)}
                    />
                    Usage
                  </div>

                  <div className="card-body">
                    <Row>
                      <Col xs="12">
                        <div className="label">{resiPlanType.value}</div>
                        {dataUsageCharts.map(dataChart => (
                          dataChart
                        ))}
                        <div className="read-only-info">
                          {resiDataUsage}
                        </div>
                        <div className="read-only-info">
                          Expiry: {resiExpiry}
                        </div>
                        <Button
                          disabled={resiPlanType.value === 'HollowVoid' || userResiPremPlans.length === 0}
                          className="btn-dashboard btn-dark w-100"
                          onClick={refreshResiPremProxyAuth}
                        >
                          Refresh Proxy Auth
                        </Button>
                        <div className="label">Proxy Authentication</div>
                        <div className="read-only-info">
                          {resiProxyAuth}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>

              <Col lg="12" className="mt-3">
                <div className="dashboard-card account-card">
                  <div className="card-header">
                    <img
                      alt="..."
                      className="card-header-img"
                      src={require(`assets/img/dashboard_account.png`)}
                    />
                    Account
                  </div>

                  <div className="card-body">
                    <Row>
                      <Col lg="6" xs="12">
                        <div className="label">Guidance</div>
                        <Button
                          className="btn-dashboard"
                          href="#"
                          onClick={(e) => { e.preventDefault(); }}
                          id="dropGuide"
                        >
                          Drop Guide
                        </Button>
                        <Tooltip placement="bottom" isOpen={tooltips['dropGuide']} target="dropGuide" toggle={() => toggleTooltip('dropGuide')}>
                          For further information on specific pools to run for each drop, please visit our information channels in our discord.
                        </Tooltip>
                        <Button
                          className="btn-dashboard"
                          href="#"
                          onClick={(e) => { e.preventDefault(); }}
                          id="support"
                        >
                          Support
                        </Button>
                        <Tooltip placement="bottom" isOpen={tooltips['support']} target="support" toggle={() => toggleTooltip('support')}>
                          For further support, please open a ticket in our discord and our staff members will be ready to assist you. Please read any important FAQ channels before opening a ticket.
                        </Tooltip>
                        <Button
                          className="btn-dashboard"
                          href="#"
                          onClick={(e) => { e.preventDefault(); }}
                          id="resiFAQ"
                        >
                          Resi FAQ
                        </Button>
                        <Tooltip placement="bottom" isOpen={tooltips['resiFAQ']} target="resiFAQ" toggle={() => toggleTooltip('resiFAQ')}>
                          Hollow Residential Plans<br />
                          <br />
                          HollowVoid: HollowVoid provides users with fast residential proxies with multiple locations around the world. This plan incorporates custom residential proxy pools that are specific to Hollow users only. These pools will be maintained regularly by us to ensure maximum quality between drops for users to have a premium experience. This is the lastest addition to our proxy arsenal.<br />
                          <br />
                          HollowPremium: Our OG plan, HollowPremium connects users to real residential IPs from all over the world. Our massive IP pool allows users to run any site without having to worry about bans or dead proxies. This plan provides custom pools as well intended to target your favorite sites such as Footsites, Finishline, Shopify, etc.<br />
                          <br />
                          Expiration<br />
                          HollowVoid: 60 Days<br />
                          HollowPremium: No Expiration<br />
                        </Tooltip>
                        <Button
                          className="btn-dashboard"
                          href="#"
                          onClick={(e) => { e.preventDefault(); }}
                          id="dcFAQ"
                        >
                          DC FAQ
                        </Button>
                        <Tooltip placement="bottom" isOpen={tooltips['dcFAQ']} target="dcFAQ" toggle={() => toggleTooltip('dcFAQ')}>
                          HollowISP plans provide users with high quality proxies from numerous ISPs. These provide users with fast, quality proxies to run stably on many site types. Some key features include:<br />
                          <br />
                          -	Located in VA<br />
                          -	10GBPS Uplink<br />
                          -	Unlimited Bandwidth<br />
                          -	IP Auth<br />
                          -	Unbanned on Most Sites<br />
                          -	Always Active<br />
                        </Tooltip>
                        <div className="label">User Information</div>
                        <Button
                          className="btn-dashboard btn-dark btn-logout w-100"
                          onClick={firebase.doSignOut}
                        >
                          {(user && user.displayName) || 'User'} <i className="fas fa-sign-out-alt float-right mt-1"></i>
                        </Button>
                      </Col>

                      <Col lg="6" xs="12">
                        <div className="label">Order History</div>
                        <div className="table-container orderHistory-table-container">
                          <Table responsive borderless size="xs" id="orderHistoryTable">
                            <tbody>
                              {orderHistory.map((order, index) => {
                                return (
                                  <tr key={order.createdAt}>
                                    <td>{getDate(new Date(order.createdAt * 1000))}</td>
                                    <td>{order.authToken || order.username}</td>
                                    <td>{order.planName}</td>
                                    <td>{order.dataLimit ? parseFloat(order.dataLimit).toFixed(2) + 'GB' : order.numberOfProxies + 'Proxies'}</td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>

          <Col lg="5">
            <Row>
              <Col lg="12" className="mt-3">
                <div className="dashboard-card proxy-list-card">
                  <div className="card-header">
                    <img
                      alt="..."
                      className="card-header-img"
                      src={require(`assets/img/dashboard_proxylist.svg`)}
                    />
                    Proxy List
                  </div>

                  <div className="card-body">
                    <Row>
                      <Col xs="12">
                        <textarea
                          readOnly
                          value={resiProxies}
                          id="resiProxyList"
                        />
                        <Button
                          onClick={() => copyProxies('RESI')}
                          disabled={resiPlanType.value === 'HollowVoid' ?
                            userResiPlans.length === 0 : userResiPremPlans.length === 0}
                          className="btn-dashboard btn-dark btn-action mt-2 w-50"
                        >
                          <i className="far fa-copy fa-1x"></i>
                        </Button>
                        <Button
                          onClick={() => downloadProxies('RESI')}
                          disabled={resiPlanType.value === 'HollowVoid' ?
                            userResiPlans.length === 0 : userResiPremPlans.length === 0}
                          className="btn-dashboard btn-dark btn-action mt-2 w-50"
                        >
                          <i className="fas fa-download fa-1x"></i>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>

          <Col lg="2">
            <Row>
              <Col lg="12" className="mt-3">
                <div className="dashboard-card datacenter-card">
                  <div className="card-header">
                    <img
                      alt="..."
                      className="card-header-img"
                      src={require(`assets/img/dashboard_datacenter.svg`)}
                    />
                    Datacenter
                  </div>

                  <div className="card-body">
                    <Row>
                      <Col xs="12">
                        <textarea
                          readOnly
                          value={dcProxies}
                          id="dcProxyList"
                        />
                        <Button
                          onClick={() => copyProxies('DC')}
                          disabled={dcAuthTokenList.length === 0}
                          className="btn-dashboard btn-dark btn-action mt-2 w-50"
                        >
                          <i className="far fa-copy fa-1x"></i>
                        </Button>
                        <Button
                          onClick={() => downloadProxies('DC')}
                          disabled={dcAuthTokenList.length === 0}
                          className="btn-dashboard btn-dark btn-action mt-2 w-50"
                        >
                          <i className="fas fa-download fa-1x"></i>
                        </Button>
                        <div className="read-only-info">
                          Expiry: <span className="dcPlanExpiry">{dcPlanExpiry}</span>
                        </div>
                        <Select
                          className="react-select react-select-secondary select-dashboard"
                          classNamePrefix="react-select"
                          name="dcAuthTokens"
                          id="dcAuthTokens"
                          value={dcAuthToken}
                          onChange={(e) => onChangeDCAuthToken(e.value)}
                          options={dcAuthTokenList}
                        />
                        <Button
                          onClick={renewDCPlan}
                          disabled={dcAuthTokenList.length === 0 || !isDCRenewActive}
                          className="btn-dashboard btn-dark mt-2 w-100"
                        >
                          Renew Proxies
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>

              <Col lg="12" className="mt-3">
                <div className="dashboard-card control-card">
                  <div className="card-header">
                   
                    <div className="authtype-wrapper" style={{align: "center"}}>
                      <div className={`authtype-item ${authType === 'ip'? 'active':''}`} onClick={changeAuthType}>ip</div>
                      <div className={`authtype-item ${authType === 'user'? 'active':''}`}onClick={changeAuthType}>user</div>
                    </div>
                  </div>

                  <div className="card-body">
                    <Row>
                      <Col xs="12">
                        <div className="label">IP Authentication</div>
                        <Form onSubmit={(e) => { e.preventDefault(); addSrcIPs(); }}>
                          <Input
                            disabled={dcAuthTokenList.length === 0}
                            id="addNewIpAddress"
                            className="input-dashboard"
                            value={dcIP}
                            onChange={(e) => setDCIP(e.target.value)}
                            required
                          />
                        </Form>
                        <div className="ipaddr-info">Paste in the address and press enter. <a href="https://api.ipify.org/?format=json" rel="noopener noreferrer" target="_blank">Click here</a> to find your IP.</div>
                        <Select
                          disabled={dcAuthTokenList.length === 0}
                          className="react-select react-select-secondary select-dashboard mt-2"
                          classNamePrefix="react-select"
                          name="dcSrcIPs"
                          id="dcSrcIPs"
                          value={dcSrcIP}
                          options={dcSrcIPList}
                          onChange={(e) => setDCSrcIP(dcSrcIPList.filter(ip => ip.value === e.value)[0])}
                        />
                        <Button
                          onClick={removeSrcIP}
                          disabled={dcAuthTokenList.length === 0 || dcSrcIPList.length === 1}
                          className="btn-dashboard btn-dark mt-2 w-100"
                        >
                          Remove Selected IP
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        {checkoutModals.map(checkoutModal => (
          checkoutModal
        ))}
      </Container>
    </>
  )
}

export default Dashboard;